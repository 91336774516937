import React from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';
import { graphql } from 'gatsby';
import slugify from 'slugify';

import Markdown from '../components/Markdown';
import Document from '../components/Document';
import LinkButton from '../components/LinkButton';
import Section, { SectionFullWidth } from '../components/Section';
import ImageRow from '../components/ImageRow';
import BackgroundImage from '../components/BackgroundImage';
import CoursesSection from '../components/pageSections/CoursesSection';

import { breakpoints } from '../css-utilities';

const IndexPageWrapper = ({
  data: {
    datoCmsFrontpage: {
      headerContent,
      image,
      buttons,
      bottomImages,
      seo,
      coursesHeader,
    },
  },
}) => {
  const renderButtons = buttons.map(({ title, path }, i) => (
    <LinkButton
      arrowDirection="right"
      className={buttonWrapper.className}
      dark={i === 0 ? true : false}
      key={title}
      label={title}
      to={`/${slugify(path, { lower: true })}`}
    />
  ));

  return (
    <Document seo={seo}>
      <Section>
        <div className="intro-column intro-text">
          <Markdown source={headerContent} />
          <div className="flex btn__wrapper">{renderButtons}</div>
        </div>

        <div className="intro-column img">
          <BackgroundImage url={image.url} />
        </div>
      </Section>

      <CoursesSection header={coursesHeader} />

      <SectionFullWidth noMargin noPadding>
        <ImageRow images={bottomImages} />
      </SectionFullWidth>

      {buttonWrapper.styles}

      <style jsx>
        {`
          .intro-column {
            width: 100%;
          }

          .intro-column.img {
            order: -1;
            margin-bottom: 1em;
          }

          .btn__wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-right: 10px;
          }

          @media (min-width: ${breakpoints.tablet}) {
            .intro-column.img {
              order: -1;
              margin-bottom: 1em;
            }

            .btn__wrapper {
              display: flex;
              flex-wrap: wrap;
              margin-right: 10px;
            }
          }

          @media (min-width: ${breakpoints.tablet}) {
            .intro-column.img {
              order: 0;
              width: 40%;
              margin-bottom: 0;
            }

            .intro-column.intro-text {
              width: 60%;
            }
          }
        `}
      </style>
    </Document>
  );
};

IndexPageWrapper.propTypes = {
  data: PropTypes.object.isRequired,
};

const buttonWrapper = css.resolve`
  margin: 10px 15px 10px 0;
`;

export const indexQuery = graphql`
  {
    datoCmsFrontpage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      headerContent
      image {
        url
      }
      buttons {
        title
        path
      }
      coursesHeader
      bottomImages {
        url
      }
    }
  }
`;

IndexPageWrapper.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPageWrapper;
