import React from 'react';
import PropTypes from 'prop-types';

import { bluishPurple, breakpoints } from '../../css-utilities';

const ImageRow = ({ images }) => {
  const renderImages = images.map(image => {
    return (
      <React.Fragment key={image.url}>
        <div className="img-col" />
        <style jsx>
          {`
            .img-col {
              background-image: url(${image.url});
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              display: inline-block;
              height: 100%;
              width: ${100 / images.length}%;
              opacity: 0.5;
            }
          `}
        </style>
      </React.Fragment>
    );
  });

  return (
    <div className="img-row-container skew-top">
      <div className="overlay">{renderImages}</div>
      <style jsx>
        {`
          .img-row-container {
            height: 290px;
            width: 100%;
          }

          .overlay {
            background: linear-gradient(
              101deg,
              #8432de,
              #6746da 63%,
              ${bluishPurple}
            );
            position: absolute;
            width: 100%;
            height: 100%;
          }

          .skew-top::before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            background: white;
            height: 25%;
            top: -25px;
            transform: skewy(2deg);
            z-index: 1;
          }

          @media (min-width: ${breakpoints.tablet}) {
            .skew-top::before {
              height: 50%;
              top: -30%;
            }
          }
        `}
      </style>
    </div>
  );
};

ImageRow.propTypes = {
  images: PropTypes.array.isRequired,
};

export default ImageRow;
